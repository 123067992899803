<template>
    <div class="branch-list el-content">
        <a-space>
            <a-button class="mb12" type="primary" @click="showScheme(0)"><i class="ri-add-line ri-top"></i>新增方案</a-button>
            <a-button class="mb12" type="primary" @click="showBranch(0)"><i class="ri-add-line ri-top"></i>新增分支</a-button>
        </a-space>
        <a-table :pagination="false" row-key="id" :data-source="scheme"
            :columns="[
                { title: 'ID', dataIndex: 'id' },
                { title: '方案名称', dataIndex: 'father_name' },
                { title: '简单描述', dataIndex: 'description' },
                { title: '标签说明', dataIndex: 'remark' },
                { title: '创建时间', dataIndex: 'create_time' },
                { title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
            ]"
        >
            <template #action="{record}">
                <a-space>
                    <kd-button 
                        type="success" 
                        icon="ri-git-branch-line" 
                        title="分支管理" 
                        @click="showBranchList(record.id)">
                    </kd-button>
                    <kd-button 
                        type="primary" 
                        icon="ri-edit-line" 
                        title="编辑" 
                        @click="showScheme(record)"
                        v-has="{ action: 'sass_server_add' }">
                    </kd-button>
                    <kd-button 
                        type="danger" 
                        icon="ri-delete-bin-line" 
                        title="删除" 
                        @click="deleteSchemen(record.id)"
                        v-has="{ action: 'sass_server_del' }">
                    </kd-button>
                </a-space>
            </template>
        </a-table>
        <a-modal v-model:visible="show.scheme" title="添加/编辑行业方案" @ok="saveScheme">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
				<a-form-item label="方案名称"><a-input v-model:value="schemeForm.father_name"></a-input></a-form-item>
                <a-form-item label="简单描述"><a-input v-model:value="schemeForm.description"></a-input></a-form-item>
                <a-form-item label="标签说明"><a-input v-model:value="schemeForm.remark"></a-input></a-form-item>
			</a-form>
        </a-modal>
        <a-modal v-model:visible="show.branchList" title="分支管理"  width="1200px" :footer="null" @cancel="show.branchList = false">
            <a-table class="tb-overflow" :pagination="false" row-key="server_id" :data-source="branch" :columns="[
                    { title: 'ID', dataIndex: 'server_id' },
                    { title: '分支名称', dataIndex: 'server_name' },
                    { title: '价格(元)', dataIndex: 'price', slots: { customRender: 'price' } },
                    { title: '天数', dataIndex: 'expire' },
                    { title: '应用数量(个)', dataIndex: 'program_count' },
                    { title: '状态', dataIndex: 'is_put_away', slots: { customRender: 'is_put_away' } },
                    { title: '排序', dataIndex: 'sort' },
                    { title: '创建时间', dataIndex: 'create_time' },
                    { title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
                ]" :scroll="{ y: 400 }">
                <template #price="{record}">
                    <div><span class="f12">原价：</span>{{record.price}}</div>
                    <div><span class="f12">折扣：</span>{{record.discount_price}}</div>
                </template>
                <template #is_put_away="{record}">
                    <a-tooltip class="item" title="点击设置为下架">
                        <a-tag @click="changePutAway(record.server_id)" color="#00CC66" v-if="record.is_put_away == 1">上架</a-tag>
                    </a-tooltip>
                    <a-tooltip class="item" title="点击设置上架">
                        <a-tag @click="changePutAway(record.server_id)" color="#FF6633" v-if="record.is_put_away == 0">已下架</a-tag>
                    </a-tooltip>
                </template>
                <template #action="{record}">
                    <a-button v-has="{ action: 'sass_server_add' }" @click="showBranch(record)" type="link" size="small">编辑</a-button>
                    <a-button v-has="{ action: 'sass_server_del' }" type="link" @click="deleteBranch(record.server_id)" size="small">删除</a-button>
                </template>
            </a-table>
        </a-modal>
        <a-modal v-model:visible="show.branch" title="添加/编辑分支" @ok="saveBranch" width="800px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
				<a-tabs defaultActiveKey>
					<a-tab-pane tab="基本信息" key="first">
						<a-form-item label="行业方案">
                            <a-select v-model:value="branchForm.father_id" >
                                <a-select-option :value="0">请选择行业方案</a-select-option>
                                <a-select-option v-for="(item,index) in scheme" :key="index" :value="item.id">
                                    {{item.father_name}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
						<a-form-item label="分支名称"><a-input v-model:value="branchForm.server_name"></a-input></a-form-item>
						<a-form-item label="价格"><a-input v-model:value="branchForm.price"></a-input></a-form-item>
						<a-form-item label="折扣价格"><a-input v-model:value="branchForm.discount_price"></a-input></a-form-item>
						<a-form-item label="到期天数"><a-input v-model:value="branchForm.expire" type="number"></a-input></a-form-item>
						<a-form-item label="是否上架">
							<a-radio-group v-model:value="branchForm.is_put_away">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="描述"><a-input type="textarea" :rows="4" v-model:value="branchForm.description"></a-input></a-form-item>
						<a-form-item label="排序"><a-input v-model:value="branchForm.sort"></a-input></a-form-item>
					</a-tab-pane>
					<a-tab-pane key="second" tab="权限配置" v-if="$store.state.modulesPerm.sass==1">
						<a-form-item label="选择插件">
							<a-checkbox-group v-model:value="branchForm.rule.plugins">
                                <div class="bl-plugins">
                                    <div class="bl-plugins-item" v-for="(item, i) in plugins" :key="i">
                                        <a-checkbox :value="item.key"> {{ item.value }} </a-checkbox>
                                    </div>
                                </div>
							</a-checkbox-group>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>商品条目限制</span>
								<a-tooltip title="条目限制等于-1表示不限制"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchForm.rule.goods.max_number" type="number"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>土地条目限制</span>
								<a-tooltip title="条目限制等于-1表示不限制"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchForm.rule.land.max_number" type="number"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>认养条目限制</span>
								<a-tooltip title="条目限制等于-1表示不限制"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchForm.rule.adoption.max_number" type="number"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>存储空间大小</span>
								<a-tooltip title="单位:b"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="branchForm.rule.storage.size" type="number"></a-input>
						</a-form-item>
					</a-tab-pane>
				</a-tabs>
			</a-form>
        </a-modal>

    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import serviceModel from '@/api/saas/service.js'
export default {
    setup(){
        const _d = reactive({
            scheme:[],
            branch:[],
            plugins:[],
            show:{
                scheme:false,
                branch:false,
                branchList:false,
            },
            schemeForm:null,
            branchForm:null,
        })
        getScheme(1,999)
        function getScheme(page,limit){
            serviceModel.getServerScheme(page,limit,res=>_d.scheme = res.list)
        }
        function showScheme(row){
            _d.schemeForm = {
                id:row ? row.id :0,
                remark:row ? row.remark:"",
                father_name:row ? row.father_name:"",
                description:row ? row.description:""
            }
            _d.show.scheme = true
        }
        const saveScheme = ()=>serviceModel.addOrEditServerScheme(_d.schemeForm,()=>{
            _d.show.scheme = false
            getScheme(1,999)
        })
        const deleteSchemen = (id)=>serviceModel.deleteSchemen(id,()=>getScheme(1,999))

        //展示分支信息
        function showBranchList(id){
            _d.branch = []
            serviceModel.getBranch(id,1,res=>_d.branch = res)
            _d.show.branchList = true
        }

        function showBranch(row){
            if( row ){
                serviceModel.getBranchDetail(row.server_id,1,res=>{
                    console.log('res',res);
                    
                    _d.branchForm = {
                        father_id:res ? res.father_id:0,
                        server_id:res ? res.server_id:0,
                        sort: res ? res.sort:99,
                        price: res ? res.price : "",
                        expire: res ? res.expire :"",
                        is_put_away: res ? res.is_put_away :0,
                        server_name: res ? res.server_name :"",
                        description: res ? res.description :"",
                        discount_price: res ? res.discount_price :"",
                        rule:res.rule ? {
                            goods:res ? res.rule.goods :{max_number: -1},
                            land:res ? res.rule.land :{max_number: -1},
                            adoption:res ? res.rule.adoption :{max_number: -1},
                            storage:res ? res.rule.storage :{size: 1024000000},
                            plugins:res ? res.rule.plugins :[],
                        }:{
                            goods:{max_number: -1},
                            land:{max_number: -1},
                            adoption:{max_number: -1},
                            storage:{size: 1024000000},
                            plugins:[],
                        },
                    }
                    _d.show.branch = true
                })
            }else{
                _d.branchForm = {
                    father_id:0,
                    server_id:0,
                    sort: 99,
                    price: "",
                    expire: "",
                    is_put_away:0,
                    server_name:"",
                    description:"",
                    discount_price:"",
                    rule:{
                        goods:{max_number: -1},
                        land:{max_number: -1},
                        adoption:{max_number: -1},
                        storage:{size: 1024000000},
                        plugins:[],
                    }
                }
                _d.show.branch = true
            }
            
            if( _d.plugins.length == 0 ){
                serviceModel.getSaasPlugin(res=>{
                    let temp = Object.keys(res);
                    temp.forEach(item => {
                        _d.plugins.push({ key: item, value: res[item] });
                    });
                })
            }
        }

        const saveBranch = ()=>serviceModel.addOrEditBranch(JSON.parse(JSON.stringify(_d.branchForm)),()=>{
            _d.show.branch = false
            showBranchList(_d.branchForm.father_id)
        })


        return{
            ...toRefs(_d),
            getScheme,
            showScheme,
            deleteSchemen,
            saveScheme,
            showBranch,
            showBranchList,
            saveBranch
        }
    }
}
</script>


<style scoped lang="scss">
.bl-plugins{
    display: flex;
    flex-wrap: wrap;

    &-item{
        width: 140px;
    }
}

</style>